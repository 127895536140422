import React, { useState, useContext, useEffect } from 'react'
import { Accordion, AccordionContext, useAccordionToggle } from 'react-bootstrap'
import HeadlineAndBodyTextBlock from './HeadlineAndBodyTextBlock'
import CodeExampleBlock from './CodeExampleBlock'
import GalleryBlock from './GalleryBlock'
import { addSlashInternalLink } from '../../helper'
import { PopupScheduleGetStarted } from './PackageBlock'
import { navigate } from '@reach/router'

const chevron = `<svg width="6px" height="10px" viewbox="0 0 6 10" version="1.1" xmlns: xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.6129 0.209705L1.70711 0.292893L5.24264 3.82843C5.49747 4.08325 5.58601 4.44139 5.50828 4.76801C5.57692 5.05529 5.51614 5.36814 5.32583 5.6129L5.24264 5.70711L1.70711 9.24264C1.31658 9.63316 0.683417 9.63316 0.292893 9.24264C-0.0675907 8.88216 -0.0953203 8.31493 0.209705 7.92263L0.292893 7.82843L3.353 4.767L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.653377 -0.0675907 1.22061 -0.0953203 1.6129 0.209705Z" id="Path" fill="#18222B" stroke="none" />
    </svg>`

const TitleFaqToggle = ({ children, eventKey, callback }) => {
     const currentEventKey = useContext(AccordionContext)

     const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey))

     const onKeyNameEnter = event => {
          if (event.keyCode === 13) {
               decoratedOnClick()
          }
     }

     const isCurrentEventKey = currentEventKey === eventKey

     return (
          <div
               tabIndex="0"
               role="button"
               className={`question-title ${isCurrentEventKey && 'show-faq'}`}
               onClick={decoratedOnClick}
               onKeyDown={onKeyNameEnter}
          >
               <h5>{children}</h5>
               <div className="icon" dangerouslySetInnerHTML={{ __html: chevron }}></div>
          </div>
     )
}

export const FaqAccordionBlock = ({ buttonText, faqs, headline }) => {
     const numberFaqLoad = 6
     const totalFaqs = faqs.length
     const [numberFaqCurrent, setNumberFaqCurrent] = useState(numberFaqLoad)
     const currentFaq = faqs.slice(0, buttonText ? numberFaqCurrent : totalFaqs)

     const [showSchedule, setShowSchedule] = useState(false)

     const handleClickLoad = () => {
          setNumberFaqCurrent(numberFaqCurrent + numberFaqLoad)
     }

     // click content FAQ #schedule-demo on page platform
     useEffect(() => {
          const handleScheduleDemoClick = event => {
               // const urlPricing = window.location.href
               // navigate(`${urlPricing}?view-demo=1`)
               setShowSchedule(true);
               window.history.replaceState(null, null, '?view-demo=1');
          }

          const scheduleDemoButton = document.getElementById('schedule-demo')
          if (scheduleDemoButton) {
               scheduleDemoButton.addEventListener('click', handleScheduleDemoClick)
          }

          return () => {
               if (scheduleDemoButton) {
                    scheduleDemoButton.removeEventListener('click', handleScheduleDemoClick)
               }
          }
     }, [])

     // click content FAQ #get-started on page platform
     useEffect(() => {
          const onClickGetStarted = event => {
               event.preventDefault()
               navigate(`${process.env.GATSBY_APP_PATH}/register/?navigate=true`)
          }

          const getStartedArr = document.querySelectorAll('.get-started')
          if (getStartedArr.length > 0) {
               getStartedArr.forEach((getStartedBtn) => {
                    getStartedBtn.addEventListener('click', onClickGetStarted)
               })
          }

          return () => {
               if (getStartedArr.length > 0) {
                    getStartedArr.forEach((getStartedBtn) => {
                         getStartedBtn.removeEventListener('click', onClickGetStarted)
                    })
               }
          }
     }, [])

     return (
          <>
               <PopupScheduleGetStarted showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
               <div className="faq-wrapper">
                    {headline && (
                         <div className="title">
                              <h3>{headline}</h3>
                         </div>
                    )}
                    <div className="faq-list">
                         <Accordion>
                              {currentFaq &&
                                   currentFaq.map((faq, index) => {
                                        return (
                                             <div key={index} className="faq-item">
                                                  <div className="question">
                                                       <TitleFaqToggle variant="link" eventKey={`${index}`}>
                                                            {faq.cfFaq.faqQuestion}
                                                       </TitleFaqToggle>
                                                  </div>
                                                  <div className="answer">
                                                       <Accordion.Collapse eventKey={`${index}`}>
                                                            <div
                                                                 dangerouslySetInnerHTML={{ __html: addSlashInternalLink(faq.cfFaq.faqAnswer) }}
                                                                 className="answer-content"
                                                            ></div>
                                                       </Accordion.Collapse>
                                                  </div>
                                             </div>
                                        )
                                   })}
                         </Accordion>
                    </div>
                    {numberFaqCurrent < totalFaqs && buttonText && (
                         <div className="button-wrapper">
                              <button className="button" onClick={handleClickLoad}>
                                   {buttonText}
                              </button>
                         </div>
                    )}
               </div>
          </>
     )
}

const ViewCodeToggle = ({ children, eventKey, callback, headline }) => {
     const currentEventKey = useContext(AccordionContext)
     const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey))

     const isCurrentEventKey = currentEventKey === eventKey

     return (
          <div className={`view-code ${isCurrentEventKey && 'active-view-code'}`}>
               <div className="title-item">
                    <h5>{headline}</h5>
               </div>
               <button type="button" onClick={decoratedOnClick}>
                    {children}
               </button>
          </div>
     )
}

const CodeExampleAccordionBlock = ({ headline, codeExamples, buttonText }) => {
     const handleSelect = eventKey => {
          const selected = document.getElementsByClassName('code-item')[eventKey]
          const isIE11 = !!window.MSInputMethodContext && !!document.documentMode
          setTimeout(() => {
               const selectedOffsetTop = selected.offsetParent.offsetTop + selected.offsetTop
               if (window.pageYOffset > selectedOffsetTop) {
                    if (isIE11) {
                         window.scrollTo(0, selectedOffsetTop - 50)
                    } else {
                         window.scrollTo({
                              top: selectedOffsetTop - 50,
                              behavior: 'smooth',
                         })
                    }
               }
          }, 500)
     }
     return (
          <div className="code-accordion-wrapper">
               {headline && (
                    <div className="title">
                         <h4>{headline}</h4>
                    </div>
               )}
               <div className="code-list">
                    <Accordion onSelect={handleSelect}>
                         {codeExamples &&
                              codeExamples.map((codeExample, index) => {
                                   return (
                                        <div key={index} className="code-item">
                                             <ViewCodeToggle variant="link" eventKey={`${index}`} headline={codeExample.headline}>
                                                  {codeExample.buttonText}
                                             </ViewCodeToggle>
                                             <div className="content">
                                                  <Accordion.Collapse eventKey={`${index}`}>
                                                       <div className="row">
                                                            <div
                                                                 className={!codeExample.codeExample && !codeExample.image ? 'col-lg-12' : 'col-lg-7'}
                                                            >
                                                                 <HeadlineAndBodyTextBlock
                                                                      headlines={[{ headlineValue: codeExample.headline, headlineType: 'H5' }]}
                                                                      body={[{ bodyCopy: codeExample.description }]}
                                                                 />
                                                            </div>
                                                            {(codeExample.codeExample || codeExample.image) && (
                                                                 <div className="col-lg-5">
                                                                      {codeExample.image && <GalleryBlock gallery={[codeExample.image]} />}
                                                                      {codeExample.codeExample && (
                                                                           <CodeExampleBlock
                                                                                codeSections={[{ sampleCode: codeExample.codeExample }]}
                                                                           />
                                                                      )}
                                                                 </div>
                                                            )}
                                                       </div>
                                                  </Accordion.Collapse>
                                             </div>
                                        </div>
                                   )
                              })}
                    </Accordion>
               </div>
               {buttonText && (
                    <div className="button-wrapper">
                         <button>{buttonText}</button>
                    </div>
               )}
          </div>
     )
}

const AccordionBlock = ({ faqs, buttonText, accordionType, headline, codeExamples, showButton }) => {
     if (!showButton) buttonText = ''
     switch (accordionType) {
          case 'Code Example':
               return <CodeExampleAccordionBlock headline={headline} codeExamples={codeExamples} buttonText={buttonText} />
          default:
               return <FaqAccordionBlock faqs={faqs} buttonText={buttonText} headline={headline} />
     }
}

AccordionBlock.defaultProps = {
     headline: 'Frequently Asked Questions',
}

export default AccordionBlock
