import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { setPackageId, setOpenPopupSignUp } from '../../actions/common'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import Modal from 'react-bootstrap/Modal'
import { dataPackage } from '../../../src/constants.js'
import { useCalendlyEventListener, InlineWidget } from 'react-calendly'
import Spinner from 'react-bootstrap/Spinner'
import { navigate } from '@reach/router'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const PopupSandbox = ({ showSandbox, setShowSandbox, handleShowSchedule }) => {
     const handleClose = () => setShowSandbox(false)

     const clickSandboxDemo = () => {
          setShowSandbox(false)
          handleShowSchedule()
     }
     return (
          <>
               <Modal className="popup-sanbox" show={showSandbox} onHide={handleClose} animation={false}>
                    <Modal.Body>
                         <h3>Need a large sandbox</h3>
                         <p>Request a demo and we’ll discuss your needs for a larger sandbox for testing.</p>
                         <div className="button-wrapper">
                              <button onClick={clickSandboxDemo}>Schedule a demo</button>
                         </div>
                    </Modal.Body>
               </Modal>
          </>
     )
}

export const PopupSchedule = ({ showSchedule, setShowSchedule }) => {
     // const [showSchedule, setShowSchedule] = useState(false)
     // const handleShowSchedule = () => setShowSchedule(true)

     const [isLoading, setIsLoading] = useState(true)
     const [isSuccess, setIsSuccess] = useState(false)

     const updateUrl = () => {
          if (window.location.href.includes('?view-demo=1&')) {
               let newURL = window.location.href.replace('?view-demo=1&', '?');
               window.history.replaceState(null, null, newURL);
          } else {
               let updatedURL = window.location.href.replace(/(\?|\&)view-demo=1/g, '');
               window.history.replaceState(null, null, updatedURL);
          }
     }

     const handleCloseSchedule = async () => {
          // navigate(`/pricing`)
          // const urlPricing = window.location.origin + window.location.pathname
          // navigate(`${urlPricing}`)
          setShowSchedule(false);
          setIsSuccess(false);
          updateUrl();
     }

     const clickThankSchedule = () => {
          // const urlPricing = window.location.origin + window.location.pathname
          // navigate(`${urlPricing}`)
          setShowSchedule(false);
          setIsSuccess(false);
          updateUrl();
     }

     useCalendlyEventListener({
          onEventTypeViewed: () => setIsLoading(false),
          onEventScheduled: e => {
               if (e.data.event) {
                    toast.success('Schedule a Talk to Sales Successful')
                    setIsSuccess(true)
               }
          },
     })

     return (
          <>
               <Modal className="scheduleDemo" show={showSchedule} onHide={handleCloseSchedule} animation={false} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                         {isSuccess ? (
                              <div className="section-thankyou">
                                   <h3>Thank you!</h3>
                                   <p>
                                        Your meeting has been schedule. Check you email, an invitation was sent with details and will be added to your
                                        canlendar
                                   </p>
                                   <div className="button-wrapper">
                                        <button onClick={clickThankSchedule}>Go back</button>
                                   </div>
                              </div>
                         ) : (
                              <div className="section-schedule">
                                   <h3>Schedule a Demo</h3>
                                   <p className="des">
                                        Looking to upgrade to a Business Plan? Grab a time below for an initial 15-minute discovery call. Then we'll
                                        schedule a time to review your custom pricing options
                                   </p>
                                   {isLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                                   <InlineWidget
                                        url="https://calendly.com/listenlayer-rick/schedule-a-demo-custom-plan"
                                        styles={{
                                             height: '1000px',
                                        }}
                                   />
                              </div>
                         )}
                    </Modal.Body>
               </Modal>
          </>
     )
}

export const PopupScheduleGetStarted = ({ showSchedule, setShowSchedule }) => {
     // const [showSchedule, setShowSchedule] = useState(false)
     // const handleShowSchedule = () => setShowSchedule(true)

     const [isLoading, setIsLoading] = useState(true)
     const [isSuccess, setIsSuccess] = useState(false)

     const updateUrl = () => {
          if (window.location.href.includes('?view-demo=1&')) {
               let newURL = window.location.href.replace('?view-demo=1&', '?');
               window.history.replaceState(null, null, newURL);
          } else {
               let updatedURL = window.location.href.replace(/(\?|\&)view-demo=1/g, '');
               window.history.replaceState(null, null, updatedURL);
          }
     }

     const handleCloseSchedule = async () => {
          // navigate(`/pricing`)
          // const urlPricing = window.location.origin + window.location.pathname
          // navigate(`${urlPricing}`)
          setShowSchedule(false);
          setIsSuccess(false);
          updateUrl();
     }

     const clickThankSchedule = () => {
          // const urlPricing = window.location.origin + window.location.pathname
          // navigate(`${urlPricing}`)
          setShowSchedule(false);
          setIsSuccess(false);
          updateUrl();
     }

     useCalendlyEventListener({
          onEventTypeViewed: () => setIsLoading(false),
          onEventScheduled: e => {
               if (e.data.event) {
                    toast.success('Schedule a Talk to Sales Successful')
                    setIsSuccess(true)
               }
          },
     })

     return (
          <>
               <Modal className="scheduleDemo" show={showSchedule} onHide={handleCloseSchedule} animation={false} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                         {isSuccess ? (
                              <div className="section-thankyou">
                                   <h3>Thank you!</h3>
                                   <p>
                                        Your meeting has been schedule. Check you email, an invitation was sent with details and will be added to your
                                        canlendar
                                   </p>
                                   <div className="button-wrapper">
                                        <button onClick={clickThankSchedule}>Go back</button>
                                   </div>
                              </div>
                         ) : (
                              <div className="section-schedule">
                                   <h3>Schedule a Demo</h3>
                                   <p className="des">
                                        Grab a time below for an initial 15-minute discovery call. Then we'll schedule a custom demo specifically for
                                        you.
                                   </p>
                                   {isLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                                   <InlineWidget
                                        url="https://calendly.com/listenlayer-rick/schedule-a-demo"
                                        styles={{
                                             height: '1000px',
                                        }}
                                   />
                              </div>
                         )}
                    </Modal.Body>
               </Modal>
          </>
     )
}

const PackageBlock = ({ packages }) => {
     toast.configure()

     const dispatch = useDispatch()
     const isLogin = useSelector(state => state.theme.isLogin)

     const dataPricing = [
          { packageId: '73508d5f-7dc2-45ce-b42b-49423d84717d', user: 10000, monthly: '48.75', annually: '39' },
          { packageId: '0c1f767a-a35c-46e7-a732-9eab1e1a7792', user: 25000, monthly: '73.75', annually: '59' },
          { packageId: '40522115-51ca-4540-b8c6-4fd1a4036df4', user: 50000, monthly: '123.75', annually: '99' },
          { packageId: 'dd848b96-6061-4a67-b1d8-a1469d73578b', user: 75000, monthly: '173.75', annually: '139' },
          { packageId: '1af03494-c4df-43d2-aab6-f3751dd98e85', user: 100000, monthly: '223.75', annually: '179' },
          { packageId: '7cd47912-2a72-4ee4-8ff5-e962f15d8c3d', user: 150000, monthly: '311.25', annually: '249' },
          { packageId: 'e4234c61-0fcd-4d84-8be2-2f183ccd1e74', user: 200000, monthly: '411.25', annually: '329' },
     ]

     const marks = {
          0: '< 10,000',
          10000: '10,000',
          25000: '25,000',
          50000: '50,000',
          75000: '75,000',
          100000: '100,000',
          150000: '150,000',
          200000: '200,000',
          220000: '> 200,000',
     }

     const [range, setRange] = useState(50000)
     const [showSandbox, setShowSandbox] = useState(false)
     const [showSchedule, setShowSchedule] = useState(false)

     const handleSliderChange = newValue => {
          setRange(newValue)
     }

     const proPricing = dataPricing.find(item => item.user === range)

     const handleShowSchedule = () => {
          setShowSchedule(true);
          if(window.location.href.includes("&")){
               let newUrl = window.location.href + '&view-demo=1'
               window.history.replaceState(null, null, newUrl);
          }else {
               window.history.replaceState(null, null, '?view-demo=1');
          }
     }

     const handleClickPackage = packageId => {
          if (packageId === 'schedule demo') {
               setShowSchedule(true)
               // navigate(`/pricing/?view-demo=1`)
               window.history.replaceState(null, null, '?view-demo=1');
          } else {
               if (isLogin) {
                    window.open(`${process.env.GATSBY_APP_PATH}/?defaultPackageId=${packageId}`)
               } else {
                    navigate(`${process.env.GATSBY_APP_PATH}/register/?navigate=true`)
                    // window.location.replace(`${process.env.GATSBY_APP_PATH}/register/`)
               }
          }
     }

     useEffect(() => {
          if (showSchedule) {
               document.querySelector('html').setAttribute('style', 'overflow:hidden')
          } else {
               document.querySelector('html').setAttribute('style', 'overflow:initial')
          }
     }, [showSchedule])

     useEffect(() => {
          const elements = document.querySelectorAll('.rc-slider-mark-text-active')
          const classText = document.querySelectorAll('.rc-slider-mark-text')

          for (let i = 0; i < elements.length; i++) {
               elements[i].style.display = 'none'
          }

          const activeElementIndex = Math.min(range, elements.length - 1)
          elements[activeElementIndex].style.display = 'block'

          for (let i = 0; i < classText.length; i++) {
               if (i === activeElementIndex) {
                    classText[i].style.display = 'block'
               } else {
                    classText[i].style.display = 'none'
               }
          }
     }, [range])

     return (
          <>
               <PopupScheduleGetStarted showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
               <PopupSandbox showSandbox={showSandbox} setShowSandbox={setShowSandbox} handleShowSchedule={handleShowSchedule} />
               <div className="plans-pricing">
                    <h1>Plans & Pricing</h1>
                    <p>Built for enterprise. Available to everyone.</p>
                    <p>
                         Open an account or <span onClick={handleShowSchedule}>schedule a demo</span> to get started.
                    </p>
               </div>

               <div className="pricingScale">
                    <h2>Pricing Scale with Your Website Traffic</h2>
                    <div className="pricingScale-desc">
                         <div className="monthly-user">
                              <p>Estimated Monthly Users</p>
                              {/* <a href="#">Check with Google Analytics</a> */}
                         </div>
                         <div className="range">
                              <div className="range-pricing">
                                   <Slider min={0} max={220000} step={null} marks={marks} value={range} onChange={handleSliderChange} />
                              </div>
                              {/* <div className="range-text">
                                   <p>&lt; 10,000</p>
                                   <p>&gt; 200,000</p>
                              </div> */}
                         </div>
                    </div>
               </div>

               {range <= 200000 ? (
                    <div className="packages-wrapper">
                         {dataPackage &&
                              dataPackage.map((pack, index) => {
                                   let updatePrice = pack.price
                                   let updatePriceDescription = pack.priceDescription
                                   let updatePackageId = pack.packageId

                                   if (proPricing) {
                                        updatePrice = pack.price.replace(39, proPricing.annually)
                                        updatePriceDescription = pack.priceDescription.replace('$48.75', '$' + proPricing.monthly)
                                        updatePackageId = pack.packageId.replace('pro-pricing', proPricing.packageId)
                                   }
                                   return (
                                        <div key={index} className="package-col">
                                             <div key={index} className="package-item">
                                                  <div className="package-box">
                                                       <div className="price-content">
                                                            <div className={`price ${pack.price === 'Custom Pricing' ? 'custom-pricing' : ''}`}>
                                                                 {updatePrice}
                                                            </div>
                                                            <p dangerouslySetInnerHTML={{ __html: updatePriceDescription }}></p>
                                                       </div>
                                                       <div className="package-content">
                                                            <div className="plan-content">
                                                                 <div className="name">{pack.plan}</div>
                                                                 <p>{pack.planDescription1}</p>
                                                                 <p>{pack.planDescription2}</p>
                                                            </div>
                                                            <div className="button-wrapper">
                                                                 <button onClick={() => handleClickPackage(updatePackageId)}>{pack.button}</button>
                                                            </div>
                                                       </div>
                                                  </div>
                                                  <div className="package-detail">
                                                       <div className="package-headline">{pack.headlineFeatures}</div>
                                                       <div className="package-feature">
                                                            {/* Automate Your Data */}
                                                            <div className="automate list-features">
                                                                 <p className="title">Automate Your Data</p>

                                                                 <ul>
                                                                      {pack.automate &&
                                                                           pack.automate.map((feature, index) => {
                                                                                const flagTooltip =
                                                                                     feature.name.includes('href') || feature.name.includes('span')
                                                                                let name = `<span>${feature.name}</span>`
                                                                                return (
                                                                                     <>
                                                                                          {flagTooltip ? (
                                                                                               <div className="desc-tooltip">
                                                                                                    <li
                                                                                                         key={index}
                                                                                                         dangerouslySetInnerHTML={{ __html: name }}
                                                                                                    ></li>

                                                                                                    <div className="top">
                                                                                                         <p>{feature.tooltip}</p>
                                                                                                         <i class="arrow-tooltip"></i>
                                                                                                    </div>
                                                                                               </div>
                                                                                          ) : (
                                                                                               <li
                                                                                                    key={index}
                                                                                                    dangerouslySetInnerHTML={{ __html: name }}
                                                                                               ></li>
                                                                                          )}
                                                                                     </>
                                                                                )
                                                                           })}
                                                                 </ul>
                                                            </div>

                                                            {/* Distribute */}
                                                            <div className="distribute list-features">
                                                                 <p className="title">Distribute Your Data</p>

                                                                 <ul>
                                                                      {pack.distribute &&
                                                                           pack.distribute.map((feature, index) => {
                                                                                const flagTooltip =
                                                                                     feature.name.includes('href') || feature.name.includes('span')
                                                                                let name = `<span>${feature.name}</span>`
                                                                                return (
                                                                                     <>
                                                                                          {flagTooltip ? (
                                                                                               <div className="desc-tooltip">
                                                                                                    <li
                                                                                                         key={index}
                                                                                                         dangerouslySetInnerHTML={{ __html: name }}
                                                                                                    ></li>

                                                                                                    <div className="top">
                                                                                                         <p>{feature.tooltip}</p>
                                                                                                         <i class="arrow-tooltip"></i>
                                                                                                    </div>
                                                                                               </div>
                                                                                          ) : (
                                                                                               <li
                                                                                                    key={index}
                                                                                                    dangerouslySetInnerHTML={{ __html: name }}
                                                                                               ></li>
                                                                                          )}
                                                                                     </>
                                                                                )
                                                                           })}
                                                                 </ul>
                                                            </div>

                                                            {/* Remain Compliant */}
                                                            <div className="remain list-features">
                                                                 <p className="title">Remain Compliant</p>

                                                                 <ul>
                                                                      {pack.remain &&
                                                                           pack.remain.map((feature, index) => {
                                                                                const flagTooltip =
                                                                                     feature.name.includes('href') || feature.name.includes('span')
                                                                                let name = `<span>${feature.name}</span>`
                                                                                return (
                                                                                     <>
                                                                                          {flagTooltip ? (
                                                                                               <div className="desc-tooltip">
                                                                                                    <li
                                                                                                         key={index}
                                                                                                         dangerouslySetInnerHTML={{ __html: name }}
                                                                                                    ></li>

                                                                                                    <div className="top">
                                                                                                         <p>{feature.tooltip}</p>
                                                                                                         <i class="arrow-tooltip"></i>
                                                                                                    </div>
                                                                                               </div>
                                                                                          ) : (
                                                                                               <li
                                                                                                    key={index}
                                                                                                    dangerouslySetInnerHTML={{ __html: name }}
                                                                                               ></li>
                                                                                          )}
                                                                                     </>
                                                                                )
                                                                           })}
                                                                 </ul>

                                                                 {pack.comingSoonRemain && (
                                                                      <div className="coming-soon">
                                                                           <p>Coming Soon</p>

                                                                           <ul>
                                                                                {pack.comingSoonRemain.map((feature, index) => {
                                                                                     const flagTooltip =
                                                                                          feature.name.includes('href') ||
                                                                                          feature.name.includes('span')
                                                                                     let name = `<span>${feature.name}</span>`
                                                                                     return (
                                                                                          <>
                                                                                               {flagTooltip ? (
                                                                                                    <div className="desc-tooltip">
                                                                                                         <li
                                                                                                              key={index}
                                                                                                              dangerouslySetInnerHTML={{
                                                                                                                   __html: name,
                                                                                                              }}
                                                                                                         ></li>

                                                                                                         <div className="top">
                                                                                                              <p>{feature.tooltip}</p>
                                                                                                              <i class="arrow-tooltip"></i>
                                                                                                         </div>
                                                                                                    </div>
                                                                                               ) : (
                                                                                                    <li
                                                                                                         key={index}
                                                                                                         dangerouslySetInnerHTML={{ __html: name }}
                                                                                                    ></li>
                                                                                               )}
                                                                                          </>
                                                                                     )
                                                                                })}
                                                                           </ul>
                                                                      </div>
                                                                 )}
                                                            </div>

                                                            {/* Gain Insights */}
                                                            <div className="gain list-features">
                                                                 <p className="title">Gain Insights</p>

                                                                 <ul>
                                                                      {pack.gain &&
                                                                           pack.gain.map((feature, index) => {
                                                                                const flagTooltip = feature.name.includes('span')
                                                                                let name = `<span>${feature.name}</span>`
                                                                                return (
                                                                                     <>
                                                                                          {flagTooltip ? (
                                                                                               <div className="desc-tooltip">
                                                                                                    <li
                                                                                                         key={index}
                                                                                                         dangerouslySetInnerHTML={{ __html: name }}
                                                                                                    ></li>

                                                                                                    <div className="top">
                                                                                                         <p>{feature.tooltip}</p>
                                                                                                         <i class="arrow-tooltip"></i>
                                                                                                    </div>
                                                                                               </div>
                                                                                          ) : (
                                                                                               <li
                                                                                                    key={index}
                                                                                                    dangerouslySetInnerHTML={{ __html: name }}
                                                                                               ></li>
                                                                                          )}
                                                                                     </>
                                                                                )
                                                                           })}
                                                                 </ul>

                                                                 {pack.comingSoonGain && (
                                                                      <div className="coming-soon">
                                                                           <p>Coming Soon</p>

                                                                           <ul>
                                                                                {pack.comingSoonGain.map((feature, index) => {
                                                                                     const flagTooltip =
                                                                                          feature.name.includes('href') ||
                                                                                          feature.name.includes('span')
                                                                                     let name = `<span>${feature.name}</span>`
                                                                                     return (
                                                                                          <>
                                                                                               {flagTooltip ? (
                                                                                                    <div className="desc-tooltip">
                                                                                                         <li
                                                                                                              key={index}
                                                                                                              dangerouslySetInnerHTML={{
                                                                                                                   __html: name,
                                                                                                              }}
                                                                                                         ></li>

                                                                                                         <div className="top">
                                                                                                              <p>{feature.tooltip}</p>
                                                                                                              <i class="arrow-tooltip"></i>
                                                                                                         </div>
                                                                                                    </div>
                                                                                               ) : (
                                                                                                    <li
                                                                                                         key={index}
                                                                                                         dangerouslySetInnerHTML={{ __html: name }}
                                                                                                    ></li>
                                                                                               )}
                                                                                          </>
                                                                                     )
                                                                                })}
                                                                           </ul>
                                                                      </div>
                                                                 )}
                                                            </div>

                                                            {/* Support & Consulting */}
                                                            <div className="support list-features">
                                                                 <p className="title">Support & Consulting</p>

                                                                 <ul>
                                                                      {pack.support &&
                                                                           pack.support.map((feature, index) => {
                                                                                const flagTooltip =
                                                                                     feature.name.includes('href') || feature.name.includes('span')
                                                                                let name = `<span>${feature.name}</span>`
                                                                                return (
                                                                                     <>
                                                                                          {flagTooltip ? (
                                                                                               <div className="desc-tooltip">
                                                                                                    <li
                                                                                                         key={index}
                                                                                                         dangerouslySetInnerHTML={{ __html: name }}
                                                                                                    ></li>

                                                                                                    <div className="top">
                                                                                                         <p>{feature.tooltip}</p>
                                                                                                         <i class="arrow-tooltip"></i>
                                                                                                    </div>
                                                                                               </div>
                                                                                          ) : (
                                                                                               <li
                                                                                                    key={index}
                                                                                                    dangerouslySetInnerHTML={{ __html: name }}
                                                                                               ></li>
                                                                                          )}
                                                                                     </>
                                                                                )
                                                                           })}
                                                                 </ul>
                                                            </div>

                                                            {/* Monthly Users*/}
                                                            <div className="monthly-user list-features">
                                                                 {pack.monthlyUser &&
                                                                      pack.monthlyUser.map((feature, index) => {
                                                                           let updateName = feature.name
                                                                           if (proPricing) {
                                                                                updateName = feature.name.replace(
                                                                                     '10,000 Monthly Users',
                                                                                     proPricing.user.toLocaleString('en-US') + ' Monthly Users'
                                                                                )
                                                                           }

                                                                           let name = `<span>${updateName}</span>`

                                                                           return (
                                                                                <p
                                                                                     onClick={() => {
                                                                                          if (feature.onClick) {
                                                                                               feature.onClick(setShowSandbox)
                                                                                          }
                                                                                     }}
                                                                                     key={index}
                                                                                     dangerouslySetInnerHTML={{ __html: name }}
                                                                                ></p>
                                                                           )
                                                                      })}
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   )
                              })}
                    </div>
               ) : (
                    <div className="customPlan">
                         <div className="customPlan-title">
                              <img src="/images/sliders-up-regular.png" alt="sliders-up-regular" />
                              <h3>Need 200,000+ monthly users? Let’s build a custom plan for you</h3>
                              <div className="button-wrapper">
                                   <button onClick={handleShowSchedule}>Schedule a demo</button>
                              </div>
                         </div>
                         <div className="customPlan-desc">
                              <img src="/images/founder.png" alt="Founder-CEO" />
                              <p className="desc-schedule">
                                   “Schedule a call and I’ll join to answer any questions you may have about the platform.”
                              </p>
                              <div className="founder">
                                   <h4>Zachary Randall</h4>
                                   <p>Founder & CEO, ListenLayer</p>
                              </div>
                         </div>
                    </div>
               )}

               {/* enhancements */}
               <div className="enhancements">
                    <div className="enhancements-title">
                         <h2>Enhancements</h2>
                         <p>Enhance your marketing & revenue analytics with custom data and service addons.</p>
                    </div>
                    <div className="enhancements-desc">
                         <h3>Revealed Companies*</h3>
                         <p>
                              Identify companies interacting with your website. Build a list of target accounts, monitor activity, understand buying
                              intent, and gain insights to drive marketing & sales.
                         </p>
                         <h3>Implementation & Managed Services**</h3>
                         <p>
                              Rely on our team for implementation, or collaborate in a fully-managed services arrangement that allows you to achieve
                              your goals with minimal time-investment. Annual business plans include 2 Success Sessions, and can also be expanded.
                         </p>
                         <h3>Enterprise Support</h3>
                         <p>
                              Some of the world's largest businesses rely on ListenLayer to power their marketing data. Schedule a demo to learn about
                              our Enterprise offerings.
                         </p>
                    </div>

                    <div className="enhancements-bottom">
                         <p>Schedule a demo to learn more about these addons.</p>
                         <div className="button-wrapper">
                              <button onClick={handleShowSchedule}>Schedule a demo</button>
                         </div>
                    </div>
               </div>
          </>
     )
}

export default PackageBlock
